const data = [
  {
    title: "Pré-impregnados (11/Fev)",
    author: "Michelle Leali (UNESP)",
    link: "https://www.youtube.com/watch?v=TTo0Z4ywPw4",
  },
  {
    title: "Cura de resinas termorrígidas (25/Fev)",
    author: "Veronica Calado (UFRJ)",
    link: null,
  },
  {
    title: "Compósitos com polímeros naturais (11/Mar)",
    author: "Elisabete Frollini (IQSC-USP)",
    link: null,
  },
  {
    title: "Reforços celulósicos micro e nanoestruturados (25/Mar)",
    author: "Rafael Delucis (UFPel)",
    link: null,
  },
  {
    title: "Tratamentos físicos de fibras (08/Abr)",
    author: "Daniela Becker (UDESC)",
    link: null,
  },
  {
    title: "Estruturas têxteis para compósitos (22/Abr)",
    author: "Fernanda Steffens (UFSC)",
    link: null,
  },
  {
    title: "Compósitos multi-escala (06/Mai)",
    author: "Daiane Romanzini (IFRS)",
    link: null,
  },
  {
    title: "Fadiga de compósitos (20/Mai)",
    author: "Raimundo Freire Jr (UFRN)",
    link: null,
  },
  {
    title: "Inovação em compósitos (03/Jun)",
    author: "Gilmar Lima (G12)",
    link: null,
  },
  {
    title: "Manufatura aditiva de compósitos (17/Jun)",
    author: "Danilo Carastan (UFABC)",
    link: null,
  },
  {
    title: "Biocompósitos termorrígidos (01/Jul)",
    author: "Renate Wellen (UFPB)",
    link: null,
  },
  {
    title: "Caracterização física de compósitos (05/Ago)",
    author: "Kinglston Soares (IFES)",
    link: null,
  },
  {
    title: "Ensaios não-destrutivos de compósitos (19/Ago)",
    author: "Daniel Willemann (UDESC)",
    link: null,
  },
  {
    title: "Análise fractográfica de compósitos (02/Set)",
    author: "Geraldo Cândido (ITA)",
    link: null,
  },
  {
    title: "Caracterização mecânica fundamental (16/Set)",
    author: "Ricardo de Medeiros (UDESC)",
    link: null,
  },
  {
    title: "Testes <i>in situ</i> de compósitos (30/Set)",
    author: "Humberto Almeida Jr (LUT)",
    link: null,
  },
  {
    title: "ACV aplicada a compósitos (14/Out)",
    author: "Armando Caldeira-Pires (UnB)",
    link: null,
  },
  {
    title: "Usinagem de compósitos (28/Out)",
    author: "Valério Ribeiro (UNESP)",
    link: null,
  },
  {
    title: "Juntas adesivas em compósitos (11/Nov)",
    author: "Mariana Banea (UAveiro)",
    link: null,
  },
  {
    title: "Reparos de compósitos (25/Nov)",
    author: "Arnaldo Carlos Morelli (IF-SP)",
    link: null,
  },
];

function SampeEducaList() {
  return (
    <ol style={{ listStyle: "none", margin: "0", padding: "0" }}>
      {data.map(({ title, author, link }, index) => (
        <li key={index}>
          <strong>
            {index + 1 + ". "}
            {link ? (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                dangerouslySetInnerHTML={{ __html: title }}
              ></a>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: title }}></span>
            )}
          </strong>{" "}
          – {author}
        </li>
      ))}
    </ol>
  );
}

export default SampeEducaList;
